$enable-responsive-font-sizes: true;

$theme-colors: (
    "primary": #278b22,
    "secondary": #d19f1f,
);

@import "~bootstrap/scss/bootstrap";
@import "../hamburgers/hamburgers";

@import "./uno-variable";
@import "./uno-mixin";
@import "./uno-custom";

#wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  overflow-x: hidden;
}

#header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: $zindex-fixed;
  //padding: 1rem 0;
  background-color: transparent;
  //background: #165d2f;
  //@include media-breakpoint-down(md) {
  //  background: #165d2f;
  //}
  transition: background 0.5s linear;
  border-top: 5px colors(1) solid;

  .top-bar-menu {
    display: flex;
    justify-content: space-between;
  }

  .header {
    &-icon {
      color: colors(4);
    }
  }

  &.sticky {
    background: #165d2f;
    transition: background 0.3s linear;
    box-shadow: 1px 1px 1rem 0px rgba(colors(5), $o01);

    .header {
      &-icon {
        color: colors(5);
      }
    }
  }

  .search-col {
    @include media-breakpoint-down(md) {
      padding-top: 0px !important;
      i {border: 0px !important; &:hover{ border: 0px !important;}}
    }
    .search {
      &:hover {
        i {color: colors(2) !important;
          //border: 1px solid colors(2) !important; padding: 0.3rem !important;
        }
      }
      a {color: colors(1);}
    }
  }

}

#logo {
  img {
    width: auto;
    max-height: 71px;

    @include media-breakpoint-down(md) {
      max-height: 50px;
    }
  }

  .logo {
    &-fixed {
      display: block;
    }

    &-sticky {
      display: none;
    }
  }

  &.sticky {
    .logo {
      &-fixed {
        display: none;
      }

      &-sticky {
        display: block;
      }
    }
  }
}

#navigation {
  color: colors(4);
  text-align: right;
  @include transition(0.25s all);

  &.sticky {
    color: colors(5);
    border-color: colors(9);
  }

  ul {
    display: inline-flex;
    align-items: center;

    li {
      display: inline-block;
      position: relative;
      margin: 0 1.5rem 0 0;
      padding: 1.5rem 0;
      @include transition(0.25s all);

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        >
        a {
        //  @extend .font-bold;
          color: colors(2);
        }
      }

      &:hover {
        > a {
          color: colors(2);
          border-bottom: colors(2) solid 1px;
        }

        > ul {
          opacity: 1;
          visibility: visible;
          transform: translate(-50%, 0);
          @include transition(0.25s all);
        }
      }

      a {
        //@extend .font-bold;
        //font-size: rem(14);
        color: inherit;
        text-decoration: none;
        text-transform: capitalize;
        letter-spacing: 1px;
        padding-bottom: 2px;
      }
    }

    ul {
      position: absolute;
      top: 100%;
      left: 50%;
      z-index: $zindex-popover;
      visibility: hidden;
      opacity: 0;
      border-top: 2px solid colors(1);
      transform: translate(-50%, 0.5rem);
      @include transition(0.25s all);

      display: block;
      min-width: 200px;
      background: colors(4);
      padding: 1rem 0;
      text-align: center;

      li {
        width: 100%;
        margin: 0;
        padding: 0;
        display: block;
        position: relative;

        &:hover {
          > a {
            @extend .font-regular;
            color: colors(1);
            text-decoration: none !important;
            border-color: transparent;
          }
        }

        a {
          display: block;
          position: relative;
          color: colors(3);
          @extend .font-regular;
          padding: 0.5rem 1rem;
          line-height: 1.2;

          &:hover {
            text-decoration: none !important;
          }
        }

        ul {
          top: -19px;
          margin-left: 2px;
          transform: none !important;
          right: 100%;
          left: inherit;
        }
      }


    }
  }
}

#searchModal {
  .modal-content {
    background: transparent;

    #keyword {
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
      padding: 1.5rem 2rem 1.5rem;
    }

    .btn {
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
    }
  }
}

#banner {
  position: relative;
  z-index: 1;
  //background: colors(5);
  //opacity: $o01;
  .banner-scroll {
    a{ &:hover { color:  #d19f1f !important; } }
  }

  .banner {
    &-slide {
      position: relative;
      display: flex;
      align-items: center;
      height: 70vh;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      //opacity: $o01;
      //box-shadow: inset 0px 0px 0px 1000px rgba(colors(5), $o03);
      &:before { background:rgba(colors(8),.5); content: ''; position: absolute; top:0; left: 0; width: 100%; height: 100%;  }

      @include media-breakpoint-down(xs) {
        // align-items: flex-end;
      }
    }

    &-overlay {
      // position: absolute;
      // left: 0;
      // right: 0;
      // top: 0;
      // height: 100%;
      // width: 100%;
      // background: linear-gradient(
      // 	0deg,
      // 	rgba(colors(3), 1) 0%,
      // 	rgba(colors(7), 0) 50%,
      // 	rgba(colors(7), 0) 80%,
      // 	rgba(colors(3), 1) 100%
      // );
      // z-index: 0;
    }

    &-content {
      position: relative;
      //max-width: 50%;
      z-index: 2;
      //text-shadow: -1px 1px 5px rgba(colors(5), $o04);

      @include media-breakpoint-down(lg) {
        max-width: 70%;
      }

      @include media-breakpoint-down(md) {
        max-width: 80%;
      }

      @include media-breakpoint-down(sm) {
        max-width: 88%;
      }

      @include media-breakpoint-down(xs) {
        max-width: 100%;
      }
    }

    &-dots {
      //display: none;
      height: 20px;
      position: absolute;
      right: 0;
      left: 0;
      top: 40%;
      transform: translate3d(0px,-50%,0);
      display: flex;
      justify-content: flex-end;
      z-index: 1;

      .swiper-pagination-bullet {
        display: block !important;
        margin: 10px 0px;
        color: transparent;
        border: 1px colors(4) solid;

        &.swiper-pagination-bullet-active {
          color: colors(4);
          border: 1px colors(4) solid;
          background: colors(4) !important;
        }
      }
    }

    &-nav {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;

      .swiper-button-prev,
      .swiper-button-next {
        right: 1rem;
        left: auto;
        background-image: none;
        background: rgba(colors(3), $o03);
        color: colors(6);
        height: 40px;
        width: 40px;
        border-radius: 100%;
        border: 1px solid colors(3);
        text-align: center;
        margin-top: 0px;
        transform: translateY(-50%);
        line-height: 38px;

        &:hover {
          background: colors(1);
          border-color: colors(1);
        }
      }

      .swiper-button-next {
        top: calc(50% - 1.8rem);
      }

      .swiper-button-prev {
        top: calc(50% + 1.8rem);
      }

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    &-scroll {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 4rem;
      z-index: 3;
      //text-align: left;

      @include media-breakpoint-down(md) {
        display: none;
      }

      &-button {
        display: inline-block;
        color: colors(3);

        &:hover,
        &:active,
        &:focus {
          color: colors(1);
          text-decoration: none;

          .scroll-downs {
            .mousey {
              border-color: colors(1);

              .scroller {
                background-color: colors(1);
              }
            }
          }
        }

        .scroll-downs {
          margin-bottom: 0.5rem;

          .mousey {
            position: relative;
            width: 2px;
            padding: 5px 10px;
            height: 30px;
            border: 2px solid colors(3);
            border-radius: 25px;
            opacity: 0.75;
            box-sizing: content-box;
            cursor: pointer;
            transition-duration: 500ms;

            .scroller {
              width: 2px;
              height: 5px;
              border-radius: 25%;
              background-color: colors(3);
              animation-name: scrollMouse;
              animation-duration: 2.2s;
              animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
              animation-iteration-count: infinite;
            }
          }
        }
      }
    }
  }
}

#innerBanner {
  position: relative;
  z-index: $zindex-sticky;


  .banner-title{
    position: absolute; top:50%; left:50%; transform: translate(-50%, -50%); z-index: 1;
  }

  .inner-banner {
    &-slide {
      height: 400px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      //opacity: $o01;
      //box-shadow: inset 0px 0px 0px 1000px rgba(colors(5), $o04);
      &:before { background:rgba(colors(8),.5); content: ''; position: absolute; top:0; left: 0; width: 100%; height: 100%;  }
    }

    &-title {
      //position: absolute;
      //top: 50%;
      //left: 0;
      //right: 0;
      //transform: translateY(-50%);
      //z-index: 1;
    }

    &-breadcrumb {
      //position: relative;
      //bottom: 0;
      //left: 30%;
      //top: -100%;
      //right: 0;
      //z-index: 1;


      li {
        font-size: 13px;
        + li {
          padding-left: 2rem;
          position: relative;
          font-size: 13px;
          &:before {
            position: absolute;
            left: 0.7rem;
            top: 20%;
            content: ".";
            color: inherit;
            font-size: rem(25);
            margin: 0;
            padding: 0;
            line-height: 0;
          }
        }
        a { color: colors(4)}
        i, svg { font-size: 5px; }
      }
      .breadcrumb {
        padding: 0.5rem 0;
        color: colors(4);
        background-color: transparent;
        justify-content: center;
      }
    }

    @include media-breakpoint-down(lg) {
    }

    @include media-breakpoint-down(sm) {
      &-slide {
        height: 400px;
      }
    }
  }
}

#breadcrumb {
  text-align: center;

  .breadcrumb {
    margin: 0;
    background: none;
    padding: 1rem 0;
    color: colors(5);
    font-size: rem(10);
    letter-spacing: $l01;
    text-transform: capitalize;
    border-radius: 0;
    align-items: center;

    > li {
      position: relative;
      padding: 0.5rem 0;
      line-height: 1;
      text-overflow: ellipsis;
      overflow: hidden;

      + li {
        padding-left: 2rem;

        &:before {
          position: absolute;
          left: 0.7rem;
          top: 20%;
          content: ".";
          color: inherit;
          font-size: rem(25);
          margin: 0;
          padding: 0;
          line-height: 0;
        }
      }

      // &:last-child {
      //   display: none;
      // }
    }
  }

  a {
    color: inherit;
    font-family: inherit;
    text-decoration: none;
    text-transform: capitalize;

    &:hover {
      color: colors(1);
      text-decoration: none;
    }
  }
}

#product_services {
  position: relative;
  z-index: $zindex-sticky;

  .product-services-button-prev,
  .product-services-button-next {
    position: relative;
    top: unset;
    right: unset;
    left: unset;
    transform: unset;
    background-image: none;
    color: colors(8);
    height: 40px;
    width: 40px;
    border-radius: 100%;
    border: 1px solid colors(8);
    text-align: center;
    margin-top: 0px;
    line-height: 42px;

    &:hover {
      background: colors(2);
      border-color: colors(2);
      color: colors(4);
    }
  }

  .swiper-slide {

    > a {
      display: block;
      &:before { content: ''; position: absolute; top:0; left: 0; width: 100%; height: 100%; background: linear-gradient(180deg, transparent  0%, rgba(0,0,0,1) 100%); }
    }
    .swiper-title {
      position: absolute;
      bottom: 7px;
      left: 20px;
    }

    &:hover {
      //box-shadow: 0px 0px 5px 1px rgba(14, 14, 14, 0.5);

      .swiper-title {
        color:  #d19f1f !important;
        font-size: 18px;
      }

      .swiper-title-arrow {
        display: inline;
      }
    }

  }
  //.product-swiper-button-next {
  //  top: calc(50% - 1.8rem);
  //}
  //
  //.product-swiper-button-prev {
  //  top: calc(50% + 1.8rem);
  //}
}

#related_link {
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: transparent;
    border: 1px solid green;
  }

  .swiper-slide {
    @include media-breakpoint-down(xs){
      img {
        height: 200px;
      }
    }
    &:hover {
      //box-shadow: inset 0px 0px 0px 1000px rgba(colors(5), $o03);
    }
  }


  @include media-breakpoint-down(xs){
    .title-01 {
      font-size: 40px;
    }
    .title-02 {
      font-size: 40px;
    }
  }
}

#container-news {
  position: relative;
  @include media-breakpoint-down(sm){
    margin-bottom: 6rem !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    background-image: none;
    background-color: colors(4);
    z-index: 3;
    color: colors(8);
    height: 50px;
    width: 50px;
    border-radius: 100%;
    border: 1px solid colors(3);
    text-align: center;
    margin-top: 0px;
    transform: translateY(175%);
    line-height: 49px;

    &:hover {
      background: colors(2);
      border-color: colors(4);
      color: colors(4);
    }
  }

  .swiper-button-next {
    right: 0rem;
    left: unset;
    @include media-breakpoint-down(sm){
      top: calc(100% + 1rem); transform: none !important; right: calc(50% - 60px);
    }
  }

  .swiper-button-prev {
    left: 0rem;
    right: unset;
    @include media-breakpoint-down(sm){
      top: calc(100% + 1rem); transform: none !important; left: calc(50% - 60px);
    }
  }
}

#latest_news {
  margin: 0 3rem;

  @include media-breakpoint-down(xs) {
    margin: 0;
  }

  .news {
    &-slide {
      //position: relative;
      //display: flex;
      //align-items: center;
      //height: 100vh;
      //background-position: center;
      //background-repeat: no-repeat;
      //background-size: cover;
      //box-shadow: inset 0px 0px 0px 1000px rgba(colors(5), $o03);

      @include media-breakpoint-down(xs) {
        // align-items: flex-end;
      }
    }

    &-content {
      position: relative;
      //max-width: 50%;
      z-index: 2;
      //text-shadow: -1px 1px 5px rgba(colors(5), $o04);

      .news-link {
        .news-card-body { display: flex; flex-direction: column; height: 100%; justify-content: space-between; padding: 0 15px; }
        .news-title {
          border-bottom : 1px solid rgba(0, 0, 0, 0.125) !important;
        }
        &:hover {
          text-decoration: none;
          .card { border-color: colors(1) !important; }
          .news-title {
            color: colors(1) !important;
            border-bottom : 1px colors(1) solid !important;
          }
        }
        .card {
          .news-image {
            min-height: 200px;
            overflow: hidden;
            background-repeat: no-repeat; background-size: cover;background-position: center;
          }
        }
      }

    }
  }


}

.running-div {
  position: relative;
  top: -50px;
  z-index: 2;

  &.mce-content-body{
    top: 0%;
  }
  @include media-breakpoint-down(md) {
    position: initial;
  }

  .running-div-row {
    background-color: colors(2);
    opacity: $o02;

    .each-row {
      @include media-breakpoint-down(md){
        border-bottom: colors(10) 1px solid !important;
      }
    }

    .border-right {
      border-right: colors(10) 1px solid !important;

      @include media-breakpoint-down(md){
        border-right: none !important;
      }
    }
  }
  .running-numbers {
    .odometer-value {
      text-align: center;
    }
  }
  .running-title {

  }
}

.content-inner {
  b {
    font-size: 18px;
    color: colors(11);
    font-weight: bold;
  }

  text-align: justify;
  font-size: 16px;
  color: colors(11);

}

.container-about-us {
  background-position: top right;
  background-size: 50%;
  background-repeat: no-repeat;

  .about-us-top-image {
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 50%;

  }

  .about-us-footer {
    a {
      &:hover {
        text-decoration: none;
        .arrow-class {
          background-color : colors(1);
        }
      }
      .arrow-class {
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 32px;
        position: relative;
        display: inline-block;
      }

    }


    .nav-pills {
      background-repeat: no-repeat;
      background-size: contain;
      text-align: center; padding: 4rem 0;
      //line-height: normal;

      #v-pills-vision-tab {
        color: colors(2);
        background: transparent;
        opacity: 0.5;

        &.active {
          opacity: 1;
        }
      }
      #v-pills-mission-tab {
        color: colors(1);
        background: transparent;
        opacity: 0.5;

        &.active {
          opacity: 1;
        }
      }
    }

  }
}

#top-header-menu {

  @include media-breakpoint-down(sm) {
    display: block;
  }

  .first-line-menu {
    a{
      color: colors(4);
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    &-sticky {
      display: none;
    }
  }

  line-height: 1px;
  .language-menu {
    .border-left {
      border-left: 1px solid #dee2e6 !important;
      @include media-breakpoint-down(md) {
        border: 0 !important;
      }

    }
    ul { min-width: 0;}
  }
  a {
    &:hover {
      color: colors(2) !important;
      text-decoration: none;
      cursor: pointer;
    }
  }
  ul {
    li { a{ &:hover {color: colors(2) !important; } } }
  }

}

.line-height-1 {
  line-height: 1;
}

.line-height-2{
  line-height: 2;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.base-container {
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 50%;
}

.product_services_related_link {
  background-color: #fafafa;
}

#footer{
  .social-items-list {
    .social-li-link{
      a { &:hover { color: colors(1);}}
      //@include media-breakpoint-down(lg) {
      //
      //}
      &:last-child { a{padding-right: 0;} } }
  }
  .footer-navigation {
    .home-link{
      &:first-child {
        padding-left: 0;
      }
    }
  }
}


#innerPage {

}

#mobileMenu {
  color: colors(1);
  a{
    color: #444;
  }
  li {
    color: #444;
  }
}

.template-show{ text-decoration: none; height: 100%; display: block;
  &:hover { text-decoration: none;
    .template-title {  }
    .template-date { }
    .template-desc { }
  }
  .bg-img-gallery { background-size: cover !important; background-repeat: no-repeat !important; background-position: center !important; padding-top: 70%; }
  .template-date { @extend .font-light; color:colors(7); letter-spacing: 1px; @include responsive-font-size(15); text-transform: uppercase; }
  .template-desc {  @extend .font-regular; color:colors(7); letter-spacing: 1px; @include responsive-font-size(14); }
  .template-title {  @extend .font-bold; color:colors(7); letter-spacing: 1px; @include responsive-font-size(13); }
}